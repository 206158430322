import LoginStore from './LoginStore';
import DashboardStore from "./DashboardStore";
import ErrorsStore from "./ErrorsStore";
import AllComplaintsStore from "./AllComplaintsStore";
import ChargerManagementStore from './ChargerManagementStore';
import CommonStore from './CommonStore';
import UserStore from './UserManagementStore';
import BillingStore from './BillingStore';
import AlertStore from './AlertStore';
import PaymentStore from './PaymentStore';
import CouponStore from './CouponStore';


class Stores {
  constructor() {
    this.LoginStore = new LoginStore(this);
    this.AlertStore = new AlertStore(this)
    this.DashboardStore = new DashboardStore(this);
    this.ErrorsStore = new ErrorsStore(this)
    this.AllComplaintsStore = new AllComplaintsStore(this)
    this.ChargerStore = new ChargerManagementStore(this);
    this.PaymentStore = new PaymentStore(this)
    this.UserStore = new UserStore(this)
    this.CommonStore = new CommonStore(this);
   
    this.BillingStore = new BillingStore(this)
    this.CouponStore = new CouponStore(this);
    // ... another stores
  }
}
// const stores = {
//   LoginStore,
//   DashboardStore

// };

export default Stores;
