import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { Provider } from "mobx-react";
import store from "../stores/Stores";
import AppLoader from "./cms/common/AppLoader";
import Toastr from "./cms/common/Ui/Toastr";

const Login = lazy(() => import("./landing/login/Login"));
const LandingRouter = lazy(() => import("./routers/LandingRouter"));
const ForgotPassword = lazy(() => import("./landing/forgot/ForgotPassword"));
const Verify = lazy(() => import("./landing/verify/Verify"));
const ResetSuccess = lazy(() =>
	import("./landing/change_password/ResetSuccess")
);
const ChangePassword = lazy(() =>
	import("./landing/change_password/ChangePassword")
);
const CmsRouter = lazy(() => import("./routers/CmsRouter"));
const Dashboard = lazy(() => import("./cms/dashboard/Dashboard"));
const ChargerManagement = lazy(() =>
	import("./cms/charger-management/ChargerManagement")
);
const CompanyManagement = lazy(() =>
	import("./cms/company-management/CompanyManagement")
);
const UserManagement = lazy(() =>
	import("./cms/user-management/UserManagement")
);
const Billing = lazy(() => import("./cms/billing/Billing"));
const Complaints = lazy(() => import("./cms/complaints/Complaints"));
const Reports = lazy(() => import("./cms/reports/Reports"));
const Coupon = lazy(() => import("./cms/coupon/Coupon"));
const CreatePassword = lazy(() =>
	import("./landing/create_password/CreatePassword")
);

function Application(props) {
	return (
		<Provider {...new store()}>
			<Toastr />
			<Router>
				<Suspense fallback={<AppLoader />}>
					<Switch>
						<LandingRouter exact path="/auth" component={Login} />
						<LandingRouter
							exact
							path="/auth/forgot"
							component={ForgotPassword}
						/>
						<LandingRouter exact path="/auth/verify" component={Verify} />
						<LandingRouter
							exact
							path="/auth/change-password"
							component={ChangePassword}
						/>
						<LandingRouter
							exact
							path="/auth/reset-success"
							component={ResetSuccess}
						/>
						<LandingRouter
							exact
							path="/auth/create-password"
							component={CreatePassword}
						/>
						<LandingRouter
							exact
							path="/auth/reset-password"
							component={CreatePassword}
						/>

						<CmsRouter
							exact
							path={["/", "/dashboard/:component"]}
							component={Dashboard}
						/>
						<CmsRouter
							path={["/charger-management/:component", "/charger-management"]}
							component={ChargerManagement}
						/>
						<CmsRouter
							path={["/company-management/:component", "/company-management"]}
							component={CompanyManagement}
						/>
						<CmsRouter
							exact
							path={["/user-management/:component", "/user-management"]}
							component={UserManagement}
						/>
						<CmsRouter
							exact
							path={["/billing/:component", "/billing"]}
							component={Billing}
						/>
						<CmsRouter
							exact
							path={["/complaints/:component", "/complaints"]}
							component={Complaints}
						/>
						<CmsRouter
							exact
							path={["/reports/:component", "/reports"]}
							component={Reports}
						/>
						<CmsRouter
							exact
							path={["/coupon/:component", "/coupon"]}
							component={Coupon}
						/>
					</Switch>
				</Suspense>
			</Router>
		</Provider>
	);
}

export default Application;
