import { makeAutoObservable } from 'mobx';
import ApiConstants from '../constants/ApiConstants'
import { HTTPGetRequest, HTTPRequest } from '../helpers';

class BillingStore {

    LoginStore;
    AlertStore;
    MODULE_URL = `${ApiConstants.BASE_URL}billing/`;
    isLoadingBill = false;
    isEditLoadingBill = false;
    isBillOverviewLoading = false;
    isRevenueGraphLoadingBill = false;
    isLoadingReport = false;
    isSocLoading = false;
    success = false
    login__error;
    billingsData = {}
    adminstratorData = {}
    companiesData = {}
    billOverviewData = {}
    revenueGraphData = {}
    socData = {}
    is_billing_error = undefined;
    is_down_success = false;
    is_ongoing_completed = false;
    is_stop_charging_success = false;
    is_stop_charging_loading = false;
    is_user_payment_loading = false;
    is_user_payment_success = false;
    is_user_payment_data = {}
    is_user_payment_msg = undefined
    is_company_payment_loading = false;
    is_company_payment_success = false;
    is_company_payment_data = {}
    is_company_payment_msg = undefined;
    is_reservation_loading = false;
    is_reservation_success = false;
    is_reservation_msg = null
    reservationData = []
    is_download_userPayments_success = false;
    is_download_userPayments_loading = false;
    is_download_compPayments_success = false;
    is_download_compPayments_loading = false;

    constructor(stores) {
        makeAutoObservable(this);
        this.LoginStore = stores.LoginStore;
        this.AlertStore = stores.AlertStore;
        this.userToken = this.LoginStore.userInfo.token;
        this.userId = this.LoginStore.userInfo.id;
    }
    // Set loading
    setLoading(value) {
        this.isLoadingBill = value;
    }
    // Set loading
    setReportLoading(value) {
        this.isLoadingReport = value;
    }
    // Set loading
    setEditLoading(value) {
        this.isEditLoadingBill = value;
    }

    // set soc loading
    setSocLoading = (value) => {
        this.isSocLoading = value
    }

    // set soc data
    setSocData = (data) => {
        this.socData = data.response;
    }

    // set billing errror

    setBillingError = (data) => {
        this.is_billing_error = data;
    }

    // set overview loading
    setOverviewLoading = (val) => {
        this.isBillOverviewLoading = val
    }

    // Set loading Graph
    setRevenueGraphLoading(value) {
        this.isRevenueGraphLoadingBill = value;
    }

    // Setting Helpers
    setError = (error) => {
        this.login__error = error
    }
    setSuccess = (val) => {
        this.success = val
    }

    // set Billings data
    setBillingsData = (data) => {
        this.billingsData = data.response;
    }


    // set Graph data
    setRevenueGraphData = (data) => {
        this.revenueGraphData = data.response;
    }
    // set Graph data
    setBillOverviewData = (data) => {
        this.billOverviewData = data.response;
    }

    // set report success
    setDownloadSuccess = (data) => {
        this.is_down_success = data;
    }

    //set ongoing status
    setOngoingCompleted = (data) => {
        this.is_ongoing_completed = data;
    }

    //set stop charging status
    setStopChargingSuccess = (data) => {
        this.is_stop_charging_success = data;
    }



    // set stop charging loading
    setStopChargingLoading = (value) => {
        this.is_stop_charging_loading = value;
    }

    // set user payment loading 
    setUserPaymentLoading = (value) => {
        this.is_user_payment_loading = value
    }

    // set user payment success
    setUserPaymentSuccess = (value) => {
        this.is_user_payment_success = value
    }

    // set user payment data 
    setUserPaymentData = (data) => {
        this.is_user_payment_data = data
    }

    // set user payment message
    setUserPaymentMsg = (value) => {
        this.is_user_payment_msg = value;
    }


    // set company payment loading 
    setCompanyPaymentLoading = (value) => {
        this.is_company_payment_loading = value
    }

    // set company payment success
    setCompanyPaymentSuccess = (value) => {
        this.is_company_payment_success = value
    }

    // set company payment data 
    setCompanyPaymentData = (data) => {
        this.is_company_payment_data = data
    }

    // set company payment message
    setCompanyPaymentMsg = (value) => {
        this.is_company_payment_msg = value;
    }
    // set reservation loading
    setReservationLoading(value) {
        this.is_reservation_loading = value
    }

    // set reservation success

    setReservationSuccess(value) {
        this.is_reservation_success = value;
    }

    // set reservation message
    setReservationMsg(value) {
        this.is_reservation_msg = value
    }

    // set reservation data
    setReservationData(data) {
        this.reservationData = data;
    }

    //  set download payments loading
    setDownloadUserPaymentsLoading = (value) => {
        this.is_download_userPayments_loading = value
    }

    //  set download payments 
    setDownloadUserPaymentsSuccess = (value) => {
        this.is_download_userPayments_success = value
    }
    //  set download payments loading
    setDownloadCompanyPaymentsLoading = (value) => {
        this.is_download_compPayments_loading = value
    }

    //  set download payments 
    setDownloadCompanyPaymentsSuccess = (value) => {
        this.is_download_compPayments_success = value
    }

    // One graph data is in Charging store *********************************************NOTE*********************************

    getOverviewData = () => {
        this.setOverviewLoading(true);
        HTTPRequest(`${this.MODULE_URL}overview`, {
            'x-stq-apiKey': this.userToken
        }, {
            'id': this.userId,

        }).then((res) => {
            this.setOverviewLoading(false);
            if (res.status === 200) {
                this.setBillOverviewData(res);
            }
        }).catch(e => {
            this.setError(e)
            this.setOverviewLoading(false)
        })
    }


    getRevenueGraphData = (date) => {
        this.setRevenueGraphLoading(true);
        let startDate = new Date(date[0].startDate)
        let endDate = new Date(date[0].endDate)
        let start = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`;
        let end = `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`;
        HTTPRequest(`${this.MODULE_URL}revenueBTWDates`, {
            'x-stq-apiKey': this.userToken
        }, {
            'id': this.userId,
            'startDate': start,
            'endDate': end
        }).then((res) => {
            this.setRevenueGraphLoading(false);
            if (res.status === 200) {
                this.setSuccess(true);
                this.setRevenueGraphData(res);
            }
        }).catch(e => {
            this.setError(e)
            this.setRevenueGraphLoading(false)
        })
    }



    //get all bilings
    getAllBillings = (loadable = true, apiRequest) => {
        this.setLoading(loadable);
        this.setBillingError(undefined)
        HTTPRequest(`${this.MODULE_URL}filterBookings`, {
            'x-stq-apiKey': this.userToken
        }, {
            'id': this.userId,
            ...apiRequest
        }).then((res) => {
            this.setLoading(false);
            if (res.status === 200) {
                this.setBillingsData(res);
            }
            else {
                this.setBillingError(res.response.message)
            }
        }).catch(e => {
            this.setError(e)
            this.setLoading(false)
        })
    }

    //edit bilings
    editBillings = (data, handleClose) => {
        this.setEditLoading(true);
        HTTPRequest(`${this.MODULE_URL}editBookingDetail`, {
            'x-stq-apiKey': this.userToken
        }, {

            "bookingId": data.booking_id,
            "price": data.price === null ? 0 : data.price,
            "status": data.status,
            "startTimeStamp": data.start === 'None None' ? '' : data.start,
            "stopTimeStamp": data.end === 'None None' ? '' : data.end,
            "oldUnits": data.units === null ? 0 : data.units,
            "newUnits": data.new_units === null ? 0 : parseFloat(data.new_units),
            'userId': data.userId,
            'admin_id': this.userId
        }).then((res) => {
            //this.setLoading(false);
            if (res.status === 200) {
                this.setSuccess(true);
                this.setEditLoading(false)
                this.AlertStore.setAlert('success', 'Success')
                handleClose()

            }
            else {
                this.setEditLoading(false)
                this.AlertStore.setAlert('error', res.response.message)
            }

        }).catch(e => {
            this.setError(e)
            this.setLoading(false)
        })
    }
    //download reports
    downloadReports = (data) => {
        this.setReportLoading(true);
        this.setDownloadSuccess(false);
        HTTPRequest(`${this.MODULE_URL}downloadReports`, {
            'x-stq-apiKey': this.userToken
        }, {
            'id': this.userId,
            'startDate': data.startDate,
            'endDate': data.endDate,
            'search': data.search,
            'companyId': data.companyId,
            'chargerId': data.chargerId,
            'isHub': data.isHub,
            'status': data.status
        }).then((res) => {
            if (res.status === 200) {
                this.setDownloadSuccess(true);
                this.setReportLoading(false)
                this.AlertStore.setAlert('success', 'Success')
                window.open(res.response.reportURL)

            }
            else {
                this.setDownloadSuccess(false);
                this.setReportLoading(false)
                this.AlertStore.setAlert('error', res.response.message)
            }
        }).catch(e => {
            this.setError(e)
            this.setLoading(false)
            this.AlertStore.setAlert('error', e)
        })
    }

    getSocDetails = (id) => {
        this.setSocLoading(true);
        this.setOngoingCompleted(false);
        HTTPRequest(`${this.MODULE_URL}sessiondetail`, {
            'x-stq-apiKey': this.userToken
        }, {
            'booking_id': id
        }).then((res) => {
            this.setSocLoading(false)
            if (res.status === 200 || res.status === 300) {
                this.setSocData(res)
            }
            if (res.status === 400) {
                this.setOngoingCompleted(true);
            }


        }).catch((err) => {
            this.setSocLoading(false)
        })
    }

    // stop charging 
    stopCharging = (id) => {
        this.setStopChargingLoading(true);
        this.setStopChargingSuccess(false);
        HTTPRequest(`${this.MODULE_URL}booking/stop/charging`, {
            'x-stq-apiKey': this.userToken
        }, {
            'booking_id': id
        }).then((res) => {
            this.setStopChargingLoading(false)
            if (res.status === 200) {
                this.setStopChargingSuccess(true);
                this.AlertStore.setAlert('success', res.response.message)
            }
            else {
                this.setStopChargingSuccess(false)
                this.AlertStore.setAlert('error', res.response.message)
            }
            // if(res.status === 400){
            //     this.setOngoingCompleted(true);
            // }


        }).catch((err) => {
            this.setStopChargingLoading(false)
        })
    }

    // user paymnet section 

    getUserPayment = (data, loadable = true) => {
        this.setUserPaymentLoading(loadable);
        this.setUserPaymentSuccess(false)
        this.setUserPaymentMsg(undefined)
        HTTPGetRequest(`${this.MODULE_URL}user-payments?id=${this.userId}&key=${data.searchKey}&status=${data.status}&page=${data.page}&cId=${data.companyId.toString()}`, {
            'x-stq-apiKey': this.userToken
        }).then((res) => {
            this.setUserPaymentLoading(false);
            if (res.status === 200) {
                this.setUserPaymentData(res.response);
            }
            else {
                this.setUserPaymentSuccess(false)
                this.setUserPaymentMsg(res.response.message)
            }
        }).catch(e => {
            this.setUserPaymentSuccess(false)
            this.setUserPaymentLoading(false)
            this.setUserPaymentMsg(e)
        })

    }
    //company paymnet section 

    getCompanyPayment = (data, loadable = true) => {
        this.setCompanyPaymentLoading(loadable);
        this.setCompanyPaymentSuccess(false)
        this.setCompanyPaymentMsg(undefined)
        HTTPGetRequest(`${this.MODULE_URL}company-payments?id=${this.userId}&key=${data.searchKey}&status=${data.status}&page=${data.page}&cId=${data.companyId.toString()}`, {
            'x-stq-apiKey': this.userToken
        }).then((res) => {
            this.setCompanyPaymentLoading(false);
            if (res.status === 200) {
                this.setCompanyPaymentData(res.response);
            }
            else {
                this.setCompanyPaymentSuccess(false)
                this.setCompanyPaymentMsg(res.response.message)
            }
        }).catch(e => {
            this.setCompanyPaymentSuccess(false)
            this.setCompanyPaymentLoading(false)
            this.setCompanyPaymentMsg(e)
        })

    }
    //reservations

    getReservations = (data) => {
        this.setReservationLoading(true);
        this.setReservationSuccess(false)
        this.setReservationMsg(null)
        HTTPGetRequest(`${this.MODULE_URL}reservations?id=${this.userId}&page=${data.page}`, {
            'x-stq-apiKey': this.userToken,
        }).then((res) => {
            this.setReservationLoading(false);
            if (res.status === 200) {
                this.setReservationData(res.response);
                this.setReservationSuccess(true)
            }
            else {
                this.setReservationSuccess(false)
                this.setReservationMsg(res.response.message)
            }
        }).catch(e => {
            this.setCompanyPaymentSuccess(false)
            this.setReservationLoading(false)
            this.setReservationMsg(e)
        })

    }


    // download user payment
    downloadUserPayment = (data) => {
        this.setDownloadUserPaymentsLoading(true);
        this.setDownloadUserPaymentsSuccess(false);

        HTTPGetRequest(`${this.MODULE_URL}user-payment-report?id=${this.userId}&key=${data.searchKey}&status=${data.status}&cId=${data.companyId}`, {
            'x-stq-apiKey': this.userToken
        }, {
        }).then((res) => {
            this.setDownloadUserPaymentsLoading(false);
            if (res.status === 200) {
                this.setDownloadUserPaymentsSuccess(true);
                this.AlertStore.setAlert('success', res.response.message)
                window.open(res.response.url)

            }
            else {
                this.setDownloadUserPaymentsSuccess(false);
                this.AlertStore.setAlert('error', res.response.message)
            }
        }).catch(e => {
            this.setDownloadUserPaymentsLoading(false)
        })
    }


    // download company payments
    downloadCompanyPayment = (data) => {
        this.setDownloadCompanyPaymentsLoading(true);
        this.setDownloadCompanyPaymentsSuccess(false);

        HTTPGetRequest(`${this.MODULE_URL}company-payment-report?id=${this.userId}&key=${data.searchKey}&status=${data.status}&cId=${data.companyId}`, {
            'x-stq-apiKey': this.userToken
        }, {
        }).then((res) => {
            this.setDownloadCompanyPaymentsLoading(false);
            if (res.status === 200) {
                this.setDownloadCompanyPaymentsSuccess(true);
                this.AlertStore.setAlert('success', res.response.message)
                window.open(res.response.url)

            }
            else {
                this.setDownloadCompanyPaymentsSuccess(false)
                this.AlertStore.setAlert('error', res.response.message)
            }
        }).catch(e => {
            this.setDownloadCompanyPaymentsLoading(false)
        })
    }


}

export default BillingStore;