import { makeAutoObservable, values } from 'mobx'
import ApiConstants from '../constants/ApiConstants'
import { HTTPGetRequest, HTTPRequest } from '../helpers';

class CommonStore {
    LoginStore;
    AlertStore;
    PaymentStore;
    companyGroup;
    UserStore;
    MODULE_URL = `${ApiConstants.BASE_URL}common/`;
    isLoadingCommon = false;
    isStateLoading = false;
    isCityLoading = false;
    success = false;
    error = false;
    companiesList = {};
    vehiclesList = {};
    rolesList = {};
    stateList = {};
    cityList = {};
    companyList = {};
    companiesList = {};
    bookingList = {};
    userId;
    userToken;
    company;
    is_connectors_loading = false;
    connectors_data = []
    is_companies_dropdown_loading = false;
    companiesDropdownData = [];
    is_charger_companies_dropdown_loading = false;
    chargerCompaniesDropdownData = [];
    is_roles_by_id_loading = false;
    is_roles_by_id_success = false;
    rolesByIdData = []
    is_oem_list_loading = false;
    oemListData = []
    is_companies_dropdown_loading = false;
    companyDetailsData = []
    is_get_cpo_loading = false
    get_cpo_error = ''
    getCpoData = []
    is_get_client_loading = false
    get_client_error = ''
    getClientData = []
    add_cpo_loading = false
    add_cpo_success = false
    add_client_loading = false
    add_client_success = false
    is_roles_structure_loading = false;
    rolesStructureData = {}
    is_roles_structure_err = undefined


    constructor(stores) {
        // console.log("store", stores)
        makeAutoObservable(this);
        this.LoginStore = stores.LoginStore;
        this.PaymentStore = stores.PaymentStore;
        this.userToken = this.LoginStore.userInfo.token;
        this.userId = this.LoginStore.userInfo.id;
        this.company = this.LoginStore.userInfo.companyId;
        this.companyGroup = this.LoginStore.userInfo.companyGroup;
        this.AlertStore = stores.AlertStore;
        this.ChargerStore = stores.ChargerStore;
        this.UserStore = stores.UserStore;
    }



    // Set loading
    setLoading(value) {
        this.isLoadingCommon = value;
    }

    // Set state loading
    setStateLoading(value) {
        this.isStateLoading = value;
    }
    setCityLoading(value) {
        this.isCityLoading = value;
    }

    //setCompaniesList

    setCompaniesList(data) {
        this.companiesList = data.response;
    }

    //setStateList
    setStateList(data) {
        this.stateList = data.response;
    }

    //setCityList
    setCityList(data) {
        this.cityList = data.response;
    }

    //setCompanyList
    setCompanyList(data) {
        this.companyList = data.response
    }

    //setvehiclesList

    setVehiclesList(data) {
        this.vehiclesList = data.response;
    }
    //setrolesList

    setRolesList(data) {
        this.rolesList = data.response;
    }
    //setbookingList

    setBookingList(data) {
        this.bookingList = data.response;
    }

    // Setting Helpers
    setError = (error) => {
        this.error = error
    }
    setSuccess = (val) => {
        this.success = val
    }

    // set connectors loading 
    setConnectorsLoading(value) {
        this.is_connectors_loading = value
    }

    // set connectors data
    setConnectorsData(data) {
        this.connectors_data = data.response
    }

    // set companies dropdown loading 
    setCompaniesDropdownLoading(value) {
        this.is_companies_dropdown_loading = value
    }

    //set companies dropsdown data

    setCompaniesDropdownData(data) {
        this.companiesDropdownData = data.response
    }

    // set companies dropdown loading 
    setChargerCompaniesDropdownLoading(value) {
        this.is_charger_companies_dropdown_loading = value
    }

    //set companies dropsdown data

    setChargerCompaniesDropdownData(data) {
        this.chargerCompaniesDropdownData = data.response
    }

    // set roles by id loading 
    setRolesByIdLoading(value) {
        this.is_roles_by_id_loading = value;
    }

    // set roles by id response
    setRolesByIdData(data) {
        this.rolesByIdData = data.response;
    }

    // set roles by id success
    setRolesByIdSuccess(data) {
        this.is_roles_by_id_success = data;
    }

    // set oemlist loading
    setOemListLoading = (value) => {
        this.is_oem_list_loading = value
    }
    // set oemlist data
    setOemListData = (data) => {
        this.oemListData = data.response
    }

    // set details loading 
    setGetCompanyDetailsLoading = (value) => {
        // console.log("value", value)
        this.is_company_details_loading = value
    }

    // set details loading 
    setGetCompanyDetailsData = (data) => {
        this.companyDetailsData = data.response
    }
    // set cpo loading 
    setGetCpoLoading = (value) => {
        this.is_get_cpo_loading = value
    }
    // set cpo 
    setGetCpoData = (data) => {
        this.getCpoData = data.response
    }

    // set cpo error
    setGetCpoError = (data) => {
        this.get_cpo_error = data
    }
    // set client loading 
    setGetClientLoading = (value) => {
        this.is_get_client_loading = value
    }
    // set client 
    setGetClientData = (data) => {
        this.getClientData = data.response
    }

    // set client error
    setGetClientError = (data) => {
        this.get_client_error = data
    }

    // add cpo loading
    setAddCpoLoading = (value) => {
        this.add_cpo_loading = value;
    }

    // add cpo success
    setAddCpoSuccess = (value) => {
        this.add_cpo_success = value
    }

    // add client loading
    setAddClientLoading = (value) => {
        this.add_client_loading = value;
    }

    // add cpo success
    setAddClientSuccess = (value) => {
        this.add_client_success = value
    }

    // set roles structure 
    setRolesStructureLoading = (value) => {
        this.is_roles_structure_loading = value
    }

    // set roles structure data
    setRolesStructureData = (data) => {
        this.rolesStructureData = data.response
    }

    // set roles structure error 
    setRolesStructureErr = (value) => {
        this.is_roles_structure_err = value
    }

    // Listing of all the companies

    getAllCompanies = () => {
        this.setLoading(true);
        HTTPGetRequest(`${this.MODULE_URL}listAllCompanies`,
            {
                'x-stq-apiKey': this.userToken
            }).then((res) => {
                this.setLoading(false)
                if (res.status === 200) {
                    this.setSuccess('success');
                    this.setCompaniesList(res);
                }
                else {
                    this.setError(res.response.message)
                }
            }).catch(e => {
                this.setError(e)
                this.setLoading(false)
            })


    }

    //Listing of all the Companies 

    getAllCompany = () => {
        this.setLoading(true);
        HTTPGetRequest(`${this.MODULE_URL}list-oems`,
            {
                'x-stq-apiKey': this.userToken
            }).then((res) => {
                this.setLoading(false)
                if (res.status === 200) {
                    this.setSuccess('success');
                    this.setCompanyList(res);
                }
                else {
                    this.setError(res.response.message)
                }
            }).catch(e => {
                this.setError(e)
                this.setLoading(false)
            })
    }

    // Listing of all the states

    getAllStates = () => {
        this.setStateLoading(true);
        HTTPGetRequest(`${this.MODULE_URL}state`,
            {
                'x-stq-apiKey': this.userToken
            }).then((res) => {
                this.setStateLoading(false)
                if (res.status === 200) {
                    this.setSuccess('success');
                    this.setStateList(res);
                }
                else {
                    this.setError(res.response.message)
                }
            }).catch(e => {
                this.setError(e)
                this.setStateLoading(false)
            })


    }

    // Listing of all the city

    getAllCity = (id) => {
        this.setCityLoading(true);
        HTTPGetRequest(`${this.MODULE_URL}city?stateId=${id}`,
            {
                'x-stq-apiKey': this.userToken
            }).then((res) => {
                this.setCityLoading(false)
                if (res.status === 200) {
                    this.setSuccess('success');
                    this.setCityList(res);
                }
                else {
                    this.setError(res.response.message)
                }
            }).catch(e => {
                this.setError(e)
                this.setCityLoading(false)
            })
    }

    // Listing of all the vehicles

    getAllVehicles = () => {
        this.setLoading(true);
        HTTPGetRequest(`${this.MODULE_URL}listAllVehicles`,
            {
                'x-stq-apiKey': this.userToken
            }).then((res) => {
                this.setLoading(false)
                if (res.status === 200) {
                    this.setSuccess('success');
                    this.setVehiclesList(res);

                }
                else {
                    this.setError(res.response.message)
                }
            }).catch(e => {
                this.setError(e)
                this.setLoading(false)
            })


    }
    // Listing of all connectors

    getAllConnectors = () => {
        this.setConnectorsLoading(true);
        HTTPGetRequest(`${this.MODULE_URL}listConnectorType`,
            {
                'x-stq-apiKey': this.userToken
            }).then((res) => {
                this.setConnectorsLoading(false)
                if (res.status === 200) {
                    this.setConnectorsData(res);

                }
                else {
                    this.AlertStore.setAlert('error', res.response.message)
                }
            }).catch(e => {
                this.setError(e)
                this.setConnectorsLoading(false)
            })


    }
    // Listing of all the roles

    getAllRoles = (id) => {
        this.setLoading(true);
        HTTPGetRequest(`${this.MODULE_URL}listAllRoles?id=${id}`,
            {
                'x-stq-apiKey': this.userToken
            }).then((res) => {
                this.setLoading(false)
                if (res.status === 200) {
                    this.setSuccess('success');
                    this.setRolesList(res);

                }
                else {
                    this.setError(res.response.message)
                    this.AlertStore.setAlert('error', res.response.message)
                }
            }).catch(e => {
                this.setError(e)
                this.setLoading(false)
                // this.AlertStore.setAlert('error', res.response.message)
            })


    }
    // Listing of all the roles

    getRolesById = (id) => {
        this.setRolesByIdLoading(true);
        this.setRolesByIdSuccess(false)
        HTTPGetRequest(`${this.MODULE_URL}get-role?roleId=${id}`,
            {
                'x-stq-apiKey': this.userToken
            }).then((res) => {
                this.setRolesByIdLoading(false)
                if (res.status === 200) {
                    // this.setSuccess('success');

                    this.setRolesByIdData(res);
                    this.setRolesByIdSuccess(true)

                }
                else {
                    this.AlertStore.setAlert('error', res.response.message)
                }
            }).catch(e => {
                this.AlertStore.setAlert('error', e)
                this.setRolesByIdLoading(false)
            })


    }
    // Listing of all the booking

    getAllBooking = (id) => {
        this.setLoading(true);
        HTTPRequest(`${this.MODULE_URL}listUserBookings`,
            {
                'x-stq-apiKey': this.userToken
            }, {
            "userId": id
        }).then((res) => {
            this.setLoading(false)
            if (res.status === 200) {
                this.setSuccess('success');
                this.setBookingList(res);

            }
            else {
                this.setBookingList([])
                this.AlertStore.setAlert('error', res.response.message)
            }
        }).catch(e => {
            this.setError(e)
            this.setLoading(false)
        })


    }
    // Listing of all the roles

    addRoles = (data, handleClose) => {
        this.setLoading(true);
        HTTPRequest(`${this.MODULE_URL}addRole`,
            {
                'x-stq-apiKey': this.userToken
            }, {


            ...data
            // "role_name": data.name,
            // "complain_log": data.complaint_log,
            // "complain_view": data.complaint_view,
            // "complain_edit": data.complaint_edit,
            // "booking_view": data.transaction_view,
            // "booking_edit": data.transaction_edit,
            // "charger_view": data.charger_view,
            // "charger_edit": data.charger_edit,
            // "charger_add_delete": data.charger_add,
            // "company_view": data.company_view,
            // "company_edit": data.company_edit,
            // "company_add_delete": data.company_add,
            // "users_view": data.user_view,
            // "users_edit": data.user_edit,
            // "users_add_delete": data.user_add,
            // "charger_reports": data.charger_report,
            // "billing_reports": data.billing_report,
            // "user_reports": data.user_report,
            // "complain_reports": data.complaint_report
        }).then((res) => {
            this.setLoading(false)
            if (res.status === 200) {
                this.setSuccess(true);
                handleClose()

            }

        }).catch(e => {
            this.setError(e)
            this.setLoading(false)
        })


    }

    // Add Companies
    addCompany = (data, handleAddClose) => {
        this.setLoading(true);
        // console.log(data)
        HTTPRequest(`${this.MODULE_URL}addCompany`,
            {
                'x-stq-apiKey': this.userToken
            }, {
            'companyName': data.name,
            'MID': data.mid ? parseInt(data.mid) : '',
            'percent': data.percent ? parseFloat(data.percent) : '',
            'parentId': parseInt(data.parentId ? data.parentId : 0),
            'chargerPrice': data.chargerPrice
        }).then((res) => {
            this.setLoading(false)
            if (res.status === 200) {
                this.AlertStore.setAlert('success', res.response.message)
                handleAddClose()
            }
            else {

                this.AlertStore.setAlert('error', res.response.message)
            }
        }).catch(e => {
            this.setError(e)
            this.setLoading(false)
        })


    }
    // edit Companies
    editCompany = (data, handleEditClose) => {
        this.setLoading(true);
        HTTPRequest(`${this.MODULE_URL}editCompanyName`,
            {
                'x-stq-apiKey': this.userToken
            }, {
            'companyName': data.name,
            'companyId': data.id
        }).then((res) => {
            this.setLoading(false)
            if (res.status === 200) {
                this.AlertStore.setAlert('success', res.response.message)
                handleEditClose()
            }
            else {
                this.AlertStore.setAlert('error', res.response.message)
            }
        }).catch(e => {
            this.setError(e)
            this.setLoading(false)
        })
    }

    // get company list with childs
    getAllCompaniesDropdown = () => {
        this.setCompaniesDropdownLoading(true);
        this.setCompaniesDropdownData([])
        HTTPGetRequest(`${this.MODULE_URL}list-company?id=${this.userId}`,
            {
                'x-stq-apiKey': this.userToken
            }).then((res) => {
                this.setCompaniesDropdownLoading(false)
                if (res.status === 200) {
                    this.setCompaniesDropdownData(res)
                }
                else {
                    this.AlertStore.setAlert('error', res.response.message)
                }
            }).catch(e => {
                this.setError(e)
                this.setCompaniesDropdownLoading(false)
            })

    }

    // get company list with childs v2
    getAllCompaniesDropdownV2 = () => {
        this.setCompaniesDropdownLoading(true);
        this.setCompaniesDropdownData([])
        HTTPGetRequest(`${this.MODULE_URL}list-company/v2?id=${this.userId}`,
            {
                'x-stq-apiKey': this.userToken
            }).then((res) => {
                this.setCompaniesDropdownLoading(false)
                if (res.status === 200) {
                    this.setCompaniesDropdownData(res)
                }
                else {
                    this.AlertStore.setAlert('error', res.response.message)
                }
            }).catch(e => {
                this.setError(e)
                this.setCompaniesDropdownLoading(false)
            })

    }

    // get company list of chargers
    getAllChargerCompaniesDropdown = (company_id, charger_id, callback) => {
        this.setChargerCompaniesDropdownLoading(true);
        this.setChargerCompaniesDropdownData([])
        HTTPGetRequest(`${this.MODULE_URL}list-company-by-charger?company_id=${company_id}&charger_id=${charger_id}`,
            {
                'x-stq-apiKey': this.userToken
            }).then((res) => {
                this.setChargerCompaniesDropdownLoading(false)
                if (res.status === 200) {

                    callback(res.response.data)
                    this.setChargerCompaniesDropdownData(res)
                }
                else {
                    this.AlertStore.setAlert('error', res.response.message)
                }
            }).catch(e => {
                this.setError(e)
                this.setChargerCompaniesDropdownLoading(false)
            })

    }


    // get oem list of chargers
    getAllOemList = () => {
        this.setOemListLoading(true);
        this.setOemListData([])
        HTTPGetRequest(`${this.MODULE_URL}list-oems`,
            {
                'x-stq-apiKey': this.userToken
            }).then((res) => {
                this.setOemListLoading(false)
                if (res.status === 200) {
                    this.setOemListData(res)
                }
                else {
                    this.AlertStore.setAlert('error', res.response.message)
                }
            }).catch(e => {
                this.setError(e)
                this.setOemListLoading(false)
            })

    }


    // get company details
    getCompanyDetails = () => {
        this.setGetCompanyDetailsLoading(true);
        this.setGetCompanyDetailsData([])
        this.ChargerStore.setLicenseSuccess(false)
        HTTPGetRequest(`${this.MODULE_URL}companyDetails?id=${this.company}`,
            {
                'x-stq-apiKey': this.userToken
            }).then((res) => {
                this.setGetCompanyDetailsLoading(false)
                if (res.status === 200) {
                    this.setGetCompanyDetailsData(res)
                }
                else {
                    this.AlertStore.setAlert('error', res.response.message)
                }
            }).catch(e => {
                this.setError(e)
                this.setGetCompanyDetailsLoading(false)

            })

    }



    // get cpo list
    getCpoList = (page, details = 1) => {
        this.setGetCpoLoading(true);
        this.PaymentStore.resetRazorPaySuccess()
        this.setGetCpoError('')
        this.setAddCpoSuccess(false)
        this.UserStore.setAddCreditSuccess(false)
        HTTPGetRequest(`${this.MODULE_URL}listCPO?details=${details}&page=${page}&id=${this.companyGroup !== 'CPO' ? this.company : ''}`,
            {
                'x-stq-apiKey': this.userToken
            }).then((res) => {
                this.setGetCpoLoading(false)
                if (res.status === 200) {
                    this.setGetCpoData(res)
                }
                else {
                    this.setGetCpoError(res.response.message)
                    this.AlertStore.setAlert('error', res.response.message)
                }
            }).catch(e => {
                this.setError(e)
                this.setGetCpoLoading(false)

            })

    }

    // add cpo 
    addCpo = (data) => {
        this.setAddCpoLoading(true);
        this.setAddCpoSuccess(false);

        HTTPRequest(`${this.MODULE_URL}addCPO`,
            {
                'x-stq-apiKey': this.userToken
            }, {
            admin_id: this.userId,
            ...data
        }).then((res) => {
            this.setAddCpoLoading(false)
            if (res.status === 200) {
                this.setAddCpoSuccess(true);
                this.AlertStore.setAlert('success', res.response.message)
            }
            else {
                // this.setGetCpoError(res.response.message)
                this.AlertStore.setAlert('error', res.response.message)
            }
        }).catch(e => {

            this.setAddCpoLoading(false)
            this.setAddCpoSuccess(false);
        })

    }



    // get client list
    getClientList = (data) => {
        this.setGetClientLoading(true);
        this.PaymentStore.resetRazorPaySuccess()
        this.setAddClientSuccess(false);
        this.UserStore.setAddCreditSuccess(false)
        // console.log(this.PaymentStore)
        // this.setGetCpoData([])
        this.setGetClientError('')
        HTTPGetRequest(`${this.MODULE_URL}listClient?cpo=${data.cpo ? data.cpo : this.company}&page=${data.page}`,
            {
                'x-stq-apiKey': this.userToken
            }).then((res) => {
                this.setGetClientLoading(false)
                if (res.status === 200) {
                    this.setGetClientData(res)
                }
                else {
                    this.setGetClientError(res.response.message)
                    this.AlertStore.setAlert('error', res.response.message)
                }
            }).catch(e => {
                this.setError(e)
                this.setGetClientLoading(false)

            })

    }


    // add client 
    addClient = (data) => {
        this.setAddClientLoading(true);
        this.setAddClientSuccess(false);
        HTTPRequest(`${this.MODULE_URL}addHIF`,
            {
                'x-stq-apiKey': this.userToken
            }, {
            admin_id: this.userId,
            adminPhone: `91${data.adminPhone}`,
            ...data
        }).then((res) => {
            this.setAddClientLoading(false)
            if (res.status === 200) {
                this.setAddClientSuccess(true);
                this.AlertStore.setAlert('success', res.response.message)
            }
            else {
                // this.setGetCpoError(res.response.message)
                this.AlertStore.setAlert('error', res.response.message)
            }
        }).catch(e => {

            this.setAddClientLoading(false)
            this.setAddClientSuccess(false);
        })

    }

    // get client list
    getAllRolesStructure = (id) => {
        this.setRolesStructureLoading(true);
        HTTPGetRequest(`${this.MODULE_URL}roles?id=${id}`,
            {
                'x-stq-apiKey': this.userToken
            }).then((res) => {
                this.setRolesStructureLoading(false)
                if (res.status === 200) {
                    this.setRolesStructureData(res)
                }
                else {
                    this.setRolesStructureErr(res.response.message)
                    this.AlertStore.setAlert('error', res.response.message)
                }
            }).catch(e => {
                this.setError(e)
                this.setRolesStructureLoading(false)

            })

    }




}

export default CommonStore;